const SPECIALBLANK = "";
const SPECIALSELFNOTMARRY = "我-未婚";
const SPECIALSELFMARRY = "我-已婚";
const SPECIALSELFMARRY2 = "我-再婚";
const SPECIALFATHER = "父親";
const SPECIALFATHER2 = "父親再婚";
const SPECIALMOTHER = "母親";
const SPECIALMOTHER2 = "母親再婚";
const SPECIALMARRY2 = "再婚";
const MARRYPARTHER = "伴侶";
const DESCCOLUMN0 = "性別/狀況";
const DESCCOLUMN1 = "在世";
const DESCCOLUMN2 = "姓名";
const DESCCOLUMN3 = "備註";
const DESCCOLUMN4 = "特別身份";

export default {
  SPECIALBLANK: SPECIALBLANK,
  SPECIALSELFNOTMARRY: SPECIALSELFNOTMARRY,
  SPECIALSELFMARRY: SPECIALSELFMARRY,
  SPECIALSELFMARRY2: SPECIALSELFMARRY2,
  SPECIALFATHER: SPECIALFATHER,
  SPECIALFATHER2: SPECIALFATHER2,
  SPECIALMOTHER: SPECIALMOTHER,
  SPECIALMOTHER2: SPECIALMOTHER2,
  SPECIALMARRY2: SPECIALMARRY2,
  MARRYPARTHER: MARRYPARTHER,
  DESCCOLUMN0: DESCCOLUMN0,
  DESCCOLUMN1: DESCCOLUMN1,
  DESCCOLUMN2: DESCCOLUMN2,
  DESCCOLUMN3: DESCCOLUMN3,
  DESCCOLUMN4: DESCCOLUMN4,
  specialStatusArr: [
    SPECIALBLANK,
    SPECIALFATHER,
    SPECIALFATHER2,
    SPECIALMOTHER,
    SPECIALMOTHER2,
    SPECIALSELFNOTMARRY,
    SPECIALSELFMARRY,
    SPECIALSELFMARRY2,
    SPECIALMARRY2,
  ],
  statusArr: ["男", "女", "墮胎", "胎死"],
  treeArr: [
    [0, "孩子", 1],
    [1, "同輩", 1],
    [16, "我現任伴侶", 0],
    [17, "我前任伴侶", 0],
    [18, "我前任孩子", 0],
    [2, "父輩", 1],
    [19, "父親前任伴侶", 0],
    [20, "父親前任孩子", 0],
    [3, "母輩", 1],
    [21, "母親前任伴侶", 0],
    [22, "母親前任孩子", 0],
    [4, "祖父輩", 1],
    [5, "祖母輩", 1],
    [6, "外祖父輩", 1],
    [7, "外祖母輩", 1],
    [8, "祖父-父親", 1],
    [9, "祖父-母親", 1],
    [10, "祖母-父親", 1],
    [11, "祖母-母親", 1],
    [12, "外祖父-父親", 1],
    [13, "外祖父-母親", 1],
    [14, "外祖母-父親", 1],
    [15, "外祖母-母親", 1],
  ],
};
