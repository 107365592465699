<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="mb-5" flat>
          <v-btn @click.native="hide" color="primary" class="mr-3">
            <v-icon v-if="!hideInput">mdi-arrow-collapse-vertical</v-icon>
            <v-icon v-if="hideInput">mdi-arrow-expand-vertical</v-icon>
          </v-btn>
          <v-btn @click.native="clear" color="primary" class="mr-3">重置</v-btn>
          <v-btn @click.native="draw" color="primary" class="mr-3">繪製</v-btn>
          <v-btn @click.native="image" color="primary" class="mr-3">圖像</v-btn>
          <v-btn @click.native="print" color="primary" class="mr-3">列印</v-btn>
          <v-btn @click.native="save" color="primary" class="mr-3">儲存</v-btn>
          <v-btn @click="load" color="primary" class="mr-3">讀取</v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex-wrap: nowrap">
      <v-col cols="6" v-if="hideInput == 0">
        <v-card>
          <v-expansion-panels>
            <v-expansion-panel v-for="treedesc in treeArr" :key="treedesc[0]">
              <v-expansion-panel-header
                disable-icon-rotate
                v-if="treedesc[2] == 1"
              >
                {{ treedesc[1] }}
                <template v-slot:actions>
                  <v-icon v-if="nameDesc(treedesc[0]) == ''" color="error">
                    mdi-alert-circle
                  </v-icon>
                  <v-icon v-else color="teal"> mdi-check </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-for="(person, index) in data[treedesc[0]]"
                :key="index"
              >
                <Person
                  :key="index"
                  :index="index"
                  :rowdata="person"
                  :dataindex="treedesc[0]"
                  :statusArr="statusArr"
                  :specialStatusArr="getSpecialStatusArr(treedesc[0])"
                  :special="getSpecial(person, treedesc[0])"
                  @updaterow="updaterow"
                  @addrow="addrow"
                  @delrow="delrow"
                  @uprow="uprow"
                  @downrow="downrow"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
      <v-col class="flex-grow-1">
        <v-card>
          <canvas id="myCanvas" height="900" width="1500"></canvas>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import constants from "../constants";
import Person from "../components/Person.vue";
import fileSaver from "file-saver";
import { read } from "fs";

export default {
  components: {
    Person,
  },
  data: () => ({
    /* kk
    dataInit: [
      [[0, 0, 0, 0, "", ""]], // children
      [[1, 0, 1, 1, "kk", ""]], // self
      [
        [2, 0, 0, 0, "guma", ""],
        [1, 1, 1, 1, "pa", ""],
        [1, 1, 0, 0, "u1", ""],
        [1, 1, 0, 0, "u2", ""],
        [1, 1, 0, 0, "u3", ""],
        [2, 0, 0, 0, "guje", ""],
      ], // father
      [
        [2, 0, 1, 1, "ma", ""],
        [1, 1, 0, 0, "u1", ""],
      ], // mother
      [[1, 1, 1, 1, "祖父", ""]], // father's father
      [[2, 1, 1, 1, "祖母", ""]], // father's mother
      [[1, 1, 1, 1, "外祖父", ""]], // mother's father
      [[2, 1, 1, 1, "外祖母", ""]], // mother's mother
      [[1, 1, 0, 0, "", ""]],
      [[2, 1, 0, 0, "", ""]],
      [[1, 1, 0, 0, "", ""]],
      [[2, 1, 0, 0, "", ""]],
      [[1, 1, 0, 0, "", ""]],
      [[2, 1, 0, 0, "", ""]],
      [[1, 1, 0, 0, "", ""]],
      [[2, 1, 0, 0, "", ""]],
      [[0, 0, 0, 0, "", ""]], // self current wife/husband
      [[0, 0, 0, 0, "", ""]], // self 2nd wife/husband
      [[0, 0, 0, 0, "", ""]], // self 2nd family children
      [[2, 0, 0, 0, "", ""]], // father 2nd wife
      [[0, 0, 0, 0, "", ""]], // father 2nd family children
      [[1, 0, 0, 0, "", ""]], // mother 2nd husband
      [[0, 0, 0, 0, "", ""]], // mother 2nd family children
    ],
    */
    /* pp
    dataInit: [
      [[0, 0, 0, 0, "", ""]], // children
      [[2, 0, 1, 1, "pp", ""]], // self
      [
        [1, 0, 1, 1, "pa", ""],
        [1, 0, 0, 0, "uncle", ""],
      ], // father
      [
        [2, 0, 0, 0, "e ma", ""],
        [1, 0, 0, 0, "gor", ""],
        [2, 0, 1, 1, "ma", ""],
        [2, 0, 0, 0, "e", ""],
      ], // mother
      [[1, 1, 1, 1, "4444", ""]], // father's father
      [[2, 1, 1, 1, "5555", ""]], // father's mother
      [[1, 1, 1, 1, "6666", ""]], // mother's father
      [[2, 1, 1, 1, "7777", ""]], // mother's mother
    ],
    */
    dataInit: [
      [[0, 0, 0, 0, "", ""]], // children
      [[1, 0, 1, 1, "我", ""]], // self
      [[1, 0, 1, 1, "父親", ""]], // father
      [[2, 0, 1, 1, "母親", ""]], // mother
      [[1, 1, 1, 1, "祖父", ""]], // father's father
      [[2, 1, 1, 1, "祖母", ""]], // father's mother
      [[1, 1, 1, 1, "外祖父", ""]], // mother's father
      [[2, 1, 1, 1, "外祖母", ""]], // mother's mother
      [[1, 1, 1, 1, "", ""]],
      [[2, 1, 1, 1, "", ""]],
      [[1, 1, 1, 1, "", ""]],
      [[2, 1, 1, 1, "", ""]],
      [[1, 1, 1, 1, "", ""]],
      [[2, 1, 1, 1, "", ""]],
      [[1, 1, 1, 1, "", ""]],
      [[2, 1, 1, 1, "", ""]],
      [[0, 0, 0, 0, "", ""]], // self current wife/husband
      [[0, 0, 0, 0, "", ""]], // self 2nd wife/husband
      [[0, 0, 0, 0, "", ""]], // self 2nd family children
      [[2, 0, 0, 0, "", ""]], // father 2nd wife
      [[0, 0, 0, 0, "", ""]], // father 2nd family children
      [[1, 0, 0, 0, "", ""]], // mother 2nd husband
      [[0, 0, 0, 0, "", ""]], // mother 2nd family children
    ],
    data: [],
    statusArr: [],
    specialStatusArr: [],
    treeArr: [],
    selfName: "",
    layerLen: [],
    layerLenCS: [],
    keyStatus: [],
    offsetx: 0,
    hideInput: 0,
  }),

  mounted() {
    var canvas = document.getElementById("myCanvas");
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");

    this.specialStatusArr = this.getSpecialStatusArr();
    this.statusArr = this.getStatusArr();
    this.clear();
  },

  methods: {
    clear() {
      this.ctx.fillStyle = "#ffffff";
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fillStyle = "black";
      // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.data = JSON.parse(JSON.stringify(this.dataInit));
      this.offsetx = 0;
      this.$refs.uploader.value = "";
      this.treeArr = this.getTreeArr();
      this.getDataStatus([]);
    },

    hide() {
      this.hideInput = this.hideInput ? 0 : 1;
    },

    nameDesc(dataindex) {
      var str = "";
      if (this.data[dataindex]) {
        this.data[dataindex].map((x) => {
          if (x[0]) str = str + x[0] + ", ";
        });
      }
      if (str.length > 0) str = "(" + str.substring(0, str.length - 2) + ")";
      return str;
    },

    updaterow(row, col, val, dataindex) {
      var arr = this.statusArr;
      switch (col) {
        case 0:
          this.data[dataindex][row][col] = arr.indexOf(val) + 1;
          break;
        case 1:
          this.data[dataindex][row][col] = val ? 0 : 1;
          break;
        case 4:
          this.data[dataindex][row][col] = val;
          break;
        case 5:
          this.data[dataindex][row][col] = val;
          break;
        case 6:
          if (dataindex == 1) {
            this.data[dataindex][row][2] =
              val == constants.SPECIALSELFMARRY ||
              val == constants.SPECIALSELFNOTMARRY ||
              val == constants.SPECIALSELFMARRY2
                ? 1
                : 0;
            var status =
              val == constants.SPECIALSELFMARRY
                ? 1
                : val == constants.SPECIALSELFMARRY2
                ? 2
                : 0;
            this.data[dataindex][row][3] = status;
            this.treeArr[2][2] = status > 0 ? 1 : 0;
            this.treeArr[3][2] = status == 2 ? 1 : 0;
            this.treeArr[4][2] = status == 2 ? 1 : 0;
          } else if (dataindex == 2) {
            this.data[dataindex][row][0] =
              val == constants.SPECIALFATHER ? 1 : this.data[dataindex][row][0];
            this.data[dataindex][row][2] =
              val == constants.SPECIALFATHER || val == constants.SPECIALFATHER2
                ? 1
                : 0;
            var status =
              val == constants.SPECIALFATHER
                ? 1
                : val == constants.SPECIALFATHER2
                ? 2
                : 0;
            this.data[dataindex][row][3] = status;
            this.treeArr[6][2] = status == 2 ? 1 : 0;
            this.treeArr[7][2] = status == 2 ? 1 : 0;
          } else if (dataindex == 3) {
            this.data[dataindex][row][0] =
              val == constants.SPECIALMOTHER ? 2 : this.data[dataindex][row][0];
            this.data[dataindex][row][2] =
              val == constants.SPECIALMOTHER || val == constants.SPECIALMOTHER2
                ? 1
                : 0;
            var status =
              val == constants.SPECIALMOTHER
                ? 1
                : val == constants.SPECIALMOTHER2
                ? 2
                : 0;
            this.data[dataindex][row][3] = status;
            this.treeArr[9][2] = status == 2 ? 1 : 0;
            this.treeArr[10][2] = status == 2 ? 1 : 0;
          } else if (dataindex > 3) {
            this.data[dataindex][row][2] = 1;
            this.data[dataindex][row][3] =
              val == constants.SPECIALMARRY2 ? 2 : 1;
          } else {
            this.data[dataindex][row][2] = 0;
            this.data[dataindex][row][3] = 0;
          }
          break;
      }
      this.forceupdate = !this.forceupdate;
      this.$forceUpdate();
    },

    addrow(row, dataindex) {
      this.data[dataindex] = [
        ...this.data[dataindex].slice(0, row + 1),
        [0, 0, 0, 0, "", ""],
        ...this.data[dataindex].slice(row + 1),
      ];
      this.$forceUpdate();
    },

    delrow(row, dataindex) {
      if (row == 0) {
        this.data[dataindex] = this.data[dataindex].slice(1);
      } else if (row == this.data[dataindex].length - 1) {
        this.data[dataindex] = this.data[dataindex].slice(
          0,
          this.data[dataindex].length - 1
        );
      } else {
        this.data[dataindex] = this.data[dataindex]
          .slice(0, row)
          .concat(this.data[dataindex].slice(row + 1));
      }
      if (this.data[dataindex].length == 0) {
        this.data[dataindex] = [[0, 0, 0, 0, "", ""]];
      }
      this.$forceUpdate();
    },

    uprow(row, dataindex) {
      if (row > 0) {
        var row0 = JSON.parse(JSON.stringify(this.data[dataindex][row - 1]));
        var row1 = JSON.parse(JSON.stringify(this.data[dataindex][row]));
        this.data[dataindex][row - 1] = row1;
        this.data[dataindex][row] = row0;
        this.$forceUpdate();
      }
    },

    downrow(row, dataindex) {
      if (row < this.data[dataindex].length - 1) {
        var row0 = JSON.parse(JSON.stringify(this.data[dataindex][row]));
        var row1 = JSON.parse(JSON.stringify(this.data[dataindex][row + 1]));
        this.data[dataindex][row] = row1;
        this.data[dataindex][row + 1] = row0;
        this.$forceUpdate();
      }
    },

    getTreeArr() {
      var arr = [];
      if (constants.treeArr) {
        arr = JSON.parse(JSON.stringify(constants.treeArr));
      }
      return arr;
    },

    getStatusArr() {
      var arr = [];
      if (constants.statusArr) {
        arr = JSON.parse(JSON.stringify(constants.statusArr));
      }
      return arr;
    },

    getSpecialStatusArr(dataindex) {
      var arr = [];
      if (constants.specialStatusArr) {
        arr = JSON.parse(JSON.stringify(constants.specialStatusArr));
        switch (dataindex) {
          case 1:
            arr = arr.filter(
              (x) =>
                x == constants.SPECIALBLANK ||
                x == constants.SPECIALSELFMARRY ||
                x == constants.SPECIALSELFNOTMARRY ||
                x == constants.SPECIALSELFMARRY2
            );
            break;
          case 2:
            arr = arr.filter(
              (x) =>
                x == constants.SPECIALBLANK ||
                x == constants.SPECIALFATHER ||
                x == constants.SPECIALFATHER2
            );
            break;
          case 3:
            arr = arr.filter(
              (x) =>
                x == constants.SPECIALBLANK ||
                x == constants.SPECIALMOTHER ||
                x == constants.SPECIALMOTHER2
            );
            break;
          case 4:
            arr = [constants.SPECIALBLANK, constants.SPECIALMARRY2];
            break;
          case 5:
            arr = [constants.SPECIALBLANK, constants.SPECIALMARRY2];
            break;
          case 6:
            arr = [constants.SPECIALBLANK, constants.SPECIALMARRY2];
            break;
          case 7:
            arr = [constants.SPECIALBLANK, constants.SPECIALMARRY2];
            break;
          default:
            arr = [constants.SPECIALBLANK];
        }
      }
      return arr;
    },

    getSpecial(person, dataindex) {
      var ret = constants.SPECIALBLANK;
      switch (dataindex) {
        case 1:
          if (person[2])
            ret =
              person[3] == 1
                ? constants.SPECIALSELFMARRY
                : person[3] == 2
                ? constants.SPECIALSELFMARRY2
                : constants.SPECIALSELFNOTMARRY;
          break;
        case 2:
          if (person[2])
            ret =
              person[3] == 1
                ? constants.SPECIALFATHER
                : person[3] == 2
                ? constants.SPECIALFATHER2
                : ret;
          break;
        case 3:
          if (person[2])
            ret =
              person[3] == 1
                ? constants.SPECIALMOTHER
                : person[3] == 2
                ? constants.SPECIALMOTHER2
                : ret;
          break;
        case 4:
          if (person[2])
            ret =
              person[3] == 2 ? constants.SPECIALMARRY2 : constants.SPECIALBLANK;
          break;
        case 5:
          if (person[2])
            ret =
              person[3] == 2 ? constants.SPECIALMARRY2 : constants.SPECIALBLANK;
          break;
        case 6:
          if (person[2])
            ret =
              person[3] == 2 ? constants.SPECIALMARRY2 : constants.SPECIALBLANK;
          break;
        case 7:
          if (person[2])
            ret =
              person[3] == 2 ? constants.SPECIALMARRY2 : constants.SPECIALBLANK;
          break;
      }
      return ret;
    },

    getDataStatus(posX) {
      var i = 0;
      var pos = 0;
      this.keyStatus[0] = [0, 0, 0, 0, 0];
      for (var j = 0; j <= 15; j++) {
        i = this.data[j] ? this.data[j].findIndex((x) => x[2] == 1) : -1;
        if (j == 1 && i > -1) this.selfName = this.data[j][i][4];
        pos = posX[j] ? posX[j] : 0;
        this.keyStatus[j] = [
          i > -1 ? i : 0,
          i > -1 ? this.data[j][i][3] : 0,
          i > -1 ? (this.data[j][i][3] > 1 ? 1 : 0) : 0,
          i > -1 ? this.data[j][i][0] : 0,
          i > -1 ? pos + i : pos,
        ];
      }

      this.layerLen[5] = this.data[0].length > 0 ? 1 : 0;
      this.layerLen[4] =
        this.data[1].length > 1 ||
        (this.keyStatus[2][2] == 1 &&
          this.data[20].filter((x) => x[0]).length > 0) ||
        (this.keyStatus[3][2] == 1 &&
          this.data[22].filter((x) => x[0]).length > 0)
          ? 1
          : 0;
      this.layerLen[3] =
        this.data[2].length > 1 || this.data[3].length > 1 ? 1 : 0;
      this.layerLen[2] =
        this.data[4].length == 1 &&
        this.data[5].length == 1 &&
        this.data[6].length == 1 &&
        this.data[7].length == 1
          ? 0
          : 1;
      this.layerLen[1] = 0;
      this.layerLen[0] = 0;
      this.layerLenCS = this.layerLen.reduce(
        (s, n) => (s.push((s.at(-1) ?? 0) + n), s),
        []
      );

      this.treeArr[2][2] = this.keyStatus[1][1] > 0 ? 1 : 0;
      this.treeArr[3][2] = this.keyStatus[1][1] == 2 ? 1 : 0;
      this.treeArr[4][2] = this.keyStatus[1][1] == 2 ? 1 : 0;
      this.treeArr[6][2] = this.keyStatus[2][1] == 2 ? 1 : 0;
      this.treeArr[7][2] = this.keyStatus[2][1] == 2 ? 1 : 0;
      this.treeArr[9][2] = this.keyStatus[3][1] == 2 ? 1 : 0;
      this.treeArr[10][2] = this.keyStatus[3][1] == 2 ? 1 : 0;

      // console.log(this.keyStatus);
      // console.log(this.data);
    },

    draw() {
      this.ctx.fillStyle = "#ffffff";
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fillStyle = "black";
      // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.$refs.uploader.value = "";

      const w = 40;
      const o = 20;

      this.getDataStatus([]);

      // 1: 1 = male, 2 = female, 3 = abandon, 4 = unborn
      // 2: 0 = live, 1 = die, 2 = special
      // 3: 0 = normal, 1 = self / father / mother
      // 4: 1 = linked to other group (eg. father - mother)
      // 5: name
      // 6: note
      this.ctx.font = "15px arial";
      var pos0, pos1, pos2, pos3, pos4, pos5, pos6, pos7;
      var pos8, pos9, pos10, pos11, pos12, pos13, pos14, pos15;
      var pos18, pos20, pos22;

      var mid0 = 1;
      var mid1 =
        this.data[1].length % 2 == 0
          ? this.data[1].length / 2
          : (this.data[1].length + 1) / 2;
      var mid2 =
        this.data[2].length % 2 == 0
          ? this.data[2].length / 2
          : (this.data[2].length + 1) / 2;
      var mid3 =
        this.data[3].length % 2 == 0
          ? this.data[3].length / 2
          : (this.data[3].length + 1) / 2;
      var mid4 =
        this.data[4].length % 2 == 0
          ? this.data[4].length / 2
          : (this.data[4].length + 1) / 2;
      var mid5 =
        this.data[5].length % 2 == 0
          ? this.data[5].length / 2
          : (this.data[5].length + 1) / 2;
      var mid6 =
        this.data[6].length % 2 == 0
          ? this.data[6].length / 2
          : (this.data[6].length + 1) / 2;
      var mid7 =
        this.data[7].length % 2 == 0
          ? this.data[7].length / 2
          : (this.data[7].length + 1) / 2;
      var mid18 = this.data[18].length;
      var mid20 =
        this.data[20].length % 2 == 0
          ? this.data[20].length / 2
          : (this.data[20].length + 1) / 2;
      var mid22 =
        this.data[22].length % 2 == 0
          ? this.data[22].length / 2
          : (this.data[22].length + 1) / 2;

      var len2 = this.data[2].length + 2 * this.keyStatus[2][2];
      var len45 =
        this.data[4].length +
        2 * this.keyStatus[4][2] +
        this.data[5].length +
        2 * this.keyStatus[5][2] +
        1;
      var len3 = this.data[3].length; // + 2 * this.keyStatus[3][2];
      var len67 =
        this.data[6].length +
        2 * this.keyStatus[6][2] +
        this.data[7].length +
        2 * this.keyStatus[7][2] +
        1;

      if (len45 > len2) {
        pos4 = 1 + 2 * this.keyStatus[4][2];
        pos5 = pos4 + this.data[4].length + 1;
        pos2 = (pos4 + pos5) / 2;
      } else {
        pos2 = 1 + 2 * this.keyStatus[2][2];
        pos4 = pos2 + mid2 - 2;
        pos5 = pos4 + this.data[4].length + 1;
      }

      var shift = 0;

      pos8 = pos4 - 1;
      pos9 = pos4 + mid4;
      pos10 = pos5 - 1;
      pos11 = pos5 + mid5;

      if (pos10 - pos9 < 2) {
        shift = 2 - (pos10 - pos9);
        pos10 = pos10 + shift;
        pos11 = pos11 + shift;
        pos5 = pos5 + shift;
        if (len45 > len2) {
          pos2 = (pos4 + pos5) / 2;
        } else {
          pos2 = pos2 + shift;
        }
      }

      if (pos8 < 1) {
        shift = 1 - pos8;
      } else {
        shift = 0;
      }

      pos8 = pos8 + shift;
      pos9 = pos9 + shift;
      pos10 = pos10 + shift;
      pos11 = pos11 + shift;
      pos4 = pos4 + shift;
      pos5 = pos5 + shift;
      pos2 = pos2 + shift;

      if (len67 > len3) {
        pos6 = 1 + 2 * this.keyStatus[6][2];
        pos7 = pos6 + this.data[6].length + 1;
        pos3 = (pos6 + pos7) / 2;
      } else {
        pos3 = 1;
        pos6 = pos3 + mid3 - 2;
        pos7 = pos6 + this.data[6].length + 1;
      }

      pos12 = pos6 - 1;
      pos13 = pos6 + mid6;
      pos14 = pos7 - 1;
      pos15 = pos7 + mid7;
      if (pos14 - pos13 < 2) {
        shift = 2 - (pos14 - pos13);
        pos14 = pos14 + shift;
        pos15 = pos15 + shift;
        pos7 = pos7 + shift;
        if (len67 > len3) {
          pos3 = (pos6 + pos7) / 2;
        } else {
          pos3 = pos3 + shift;
        }
      }

      if (pos12 < 1) {
        shift = 1 - pos12;
      } else {
        shift = 0;
      }
      pos12 = pos12 + shift;
      pos13 = pos13 + shift;
      pos14 = pos14 + shift;
      pos15 = pos15 + shift;
      pos6 = pos6 + shift;
      pos7 = pos7 + shift;
      pos3 = pos3 + shift;

      var end2 = pos2 + this.data[2].length;
      var end45 = pos5 + this.data[5].length + 2 * this.keyStatus[5][2];
      var endA = end2 > end45 ? end2 : end45;
      endA = endA > pos11 + 1 ? endA : pos11 + 1;

      pos3 = endA + pos3;
      pos6 = endA + pos6;
      pos7 = endA + pos7;
      pos12 = endA + pos12;
      pos13 = endA + pos13;
      pos14 = endA + pos14;
      pos15 = endA + pos15;

      pos1 = endA - mid1 + 1;
      pos0 =
        pos1 +
        this.keyStatus[1][0] +
        (this.keyStatus[1][3] == 1 ? 1 : -1) -
        mid0 +
        1;

      pos18 = pos1 - mid18;
      pos20 = pos2 - mid20;
      pos22 = pos3 + mid22 - 1;

      var posX = [
        pos0,
        pos1,
        pos2,
        pos3,
        pos4,
        pos5,
        pos6,
        pos7,
        pos8,
        pos9,
        pos10,
        pos11,
        pos12,
        pos13,
        pos14,
        pos15,
      ];
      var layer = [5, 4, 3, 3, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1];

      this.getDataStatus(posX);

      for (var i = 0; i < layer.length; i++) {
        this.drawLayer(
          this.ctx,
          this.offsetx + posX[i],
          layer[i],
          w,
          o,
          this.data[i],
          i
        );
      }
      this.drawLayer(
        this.ctx,
        this.offsetx + pos18,
        5,
        w,
        o,
        this.data[18],
        18
      );
      this.drawLayer(
        this.ctx,
        this.offsetx + pos20,
        4,
        w,
        o,
        this.data[20],
        20
      );
      this.drawLayer(
        this.ctx,
        this.offsetx + pos22,
        4,
        w,
        o,
        this.data[22],
        22
      );
    },

    drawLayer(ctx, x, y, w, o, data, dataindex) {
      if (!data) return;
      data.map((d, i) => {
        this.drawObj(ctx, x, y, i + 1, 1, data.length, w, o, ...d, dataindex);
      });
      if (y > 1) {
        var ll = data[data.length - 1][0] ? data.length : data.length - 1;
        if (ll > 0) {
          this.drawGroupLine(ctx, x, y, 1, ll, w, o, dataindex);
        }
      }
    },

    groupStartY(y0, w, o) {
      var offsety = 0;
      if (y0 > 1) offsety = -2.5 * w;
      return offsety + 2 * (w + o) * (y0 - 1) + this.layerLenCS[y0] * (3 * w);
    },

    drawGroupLine(ctx, x0, y0, x1, x2, w, o, dataindex) {
      const y = 1;
      var xx = (w + o) * (x0 - 1);
      var yy = this.groupStartY(y0, w, o);
      // group horizontal line
      ctx.beginPath();
      ctx.moveTo(w * x1 + o * (x1 - 1) + w / 2 + xx, w * y - o + yy);
      ctx.lineTo(w * x2 + o * (x2 - 1) + w / 2 + xx, w * y - o + yy);
      // group vertical line
      if (this.layerLen[y0]) {
        if (dataindex == 0) {
          ctx.moveTo(
            w * x1 + o * (x1 - 1) + w / 2 + xx,
            w * y - 2 * (w + o) + yy
          );
          ctx.lineTo(w * x1 + o * (x1 - 1) + w / 2 + xx, w * y - o + yy);
        } else if (dataindex == 18) {
          ctx.lineTo(
            w * x2 + o * (x2 - 1) + w / 2 + xx,
            w * y - 2 * (w + o) + yy
          );
          ctx.lineTo(w * x2 + o * (x2 - 1) + w / 2 + xx, w * y - o + yy);
        } else {
          var xdiff = (x2 - x1) % 2 == 1 ? x2 - x1 - 1 : x2 - x1;
          ctx.moveTo(
            ((w + o) * xdiff) / 2 + w * x1 + o * (x1 - 1) + w / 2 + xx,
            w * y - o + yy - 2.5 * w
          );
          ctx.lineTo(
            ((w + o) * xdiff) / 2 + w * x1 + o * (x1 - 1) + w / 2 + xx,
            w * y - o + yy
          );
        }
      }
      ctx.stroke();
    },

    drawObj(ctx, x0, y0, x, y, mx, w, o, obj, d, ll, j, n, n1, dataindex) {
      var xx = (w + o) * (x0 - 1);
      var yy = this.groupStartY(y0, w, o);
      var l = y0 == 1 ? 0 : ll;
      switch (obj) {
        case 1: // male
          if (y0 == 4 && l == 1) {
            ctx.fillRect(
              w * x + o * (x - 1) + xx,
              w * y + l * (o + w + w) + yy,
              w,
              w
            );
          } else {
            ctx.strokeRect(
              w * x + o * (x - 1) + xx,
              w * y + l * (o + w + w) + yy,
              w,
              w
            );
          }

          if ((y0 == 2 || y0 == 3 || y0 == 4) && j > 0) {
            if (y0 == 4 && j > 0) {
              // wife
              ctx.beginPath();
              ctx.arc(
                w * (x + 2) + w / 2 + o * (x + 1) + xx,
                w * y + w / 2 + l * (o + w + w) + yy,
                w / 2,
                0,
                Math.PI * 2
              );
              ctx.moveTo(
                w * x + o * (x - 1) + w + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.lineTo(
                w * x + o * (x - 1) + 2 * (w + o) + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.fillText(
                this.data[16] ? this.data[16][0][4] : constants.MARRYPARTHER,
                w * x + o * (x - 1) + 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy + w + o
              );
              ctx.fillText(
                this.data[16] ? this.data[16][0][5] : "",
                w * x + o * (x - 1) + 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy + w + o + w / 2
              );
              ctx.stroke();
            }
            if (j > 1) {
              // 2nd wife
              ctx.beginPath();
              ctx.arc(
                w * x + o * (x - 1) - 2 * w - o + xx,
                w * y + w / 2 + l * (o + w + w) + yy,
                w / 2,
                0,
                Math.PI * 2
              );
              ctx.moveTo(
                w * x + o * (x - 1) - 1.5 * w - o + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.lineTo(
                w * x + o * (x - 1) + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.fillText(
                this.data[15 + 2 * dataindex]
                  ? this.data[15 + 2 * dataindex][0][4]
                  : constants.MARRYPARTHER,
                w * x + o * (x - 1) - 2.5 * w - o + xx,
                w * y + l * (o + w + w) + yy + w + o
              );
              ctx.fillText(
                this.data[15 + 2 * dataindex]
                  ? this.data[15 + 2 * dataindex][0][5]
                  : "",
                w * x + o * (x - 1) - 2.5 * w - o + xx,
                w * y + l * (o + w + w) + yy + w + o + w / 2
              );
              ctx.stroke();
            }
          }
          if (d == 1) {
            ctx.beginPath();
            ctx.moveTo(w * x + o * (x - 1) + xx, w * y + l * (o + w + w) + yy);
            ctx.lineTo(
              w * (x + 1) + o * (x - 1) + xx,
              w * (y + 1) + l * (o + w + w) + yy
            );
            ctx.moveTo(
              w * x + o * (x - 1) + xx,
              w * (y + 1) + l * (o + w + w) + yy
            );
            ctx.lineTo(
              w * (x + 1) + o * (x - 1) + xx,
              w * y + l * (o + w + w) + yy
            );
            ctx.stroke();
          }
          break;
        case 2: // female
          ctx.beginPath();
          ctx.arc(
            w * x + w / 2 + o * (x - 1) + xx,
            w * y + w / 2 + l * (o + w + w) + yy,
            w / 2,
            0,
            Math.PI * 2
          );
          if (y0 == 4 && l == 1) {
            ctx.fill();
          }
          if ((y0 == 2 || y0 == 3 || y0 == 4) && j > 0) {
            if (y0 == 4 && j > 0) {
              // husband
              ctx.strokeRect(
                w * x + o * (x - 1) - 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy,
                w,
                w
              );
              ctx.moveTo(
                w * x + o * (x - 1) - 2 * (w + o) + w + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.lineTo(
                w * x + o * (x - 1) + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.fillText(
                this.data[16] ? this.data[16][0][4] : constants.MARRYPARTHER,
                w * x + o * (x - 1) - 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy + w + o
              );
              ctx.fillText(
                this.data[16] ? this.data[16][0][5] : "",
                w * x + o * (x - 1) - 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy + w + o + w / 2
              );
            }
            if (j > 1) {
              // 2nd husband
              ctx.strokeRect(
                w * x + o * (x - 1) + 2 * (w + o) + xx,
                w * y + l * (o + w + w) + yy,
                w,
                w
              );
              ctx.moveTo(
                w * x + o * (x - 1) + w + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.lineTo(
                w * x + o * (x - 1) + 2.5 * w + o + xx,
                w * y + l * (o + w + w) + w / 2 + yy
              );
              ctx.fillText(
                this.data[15 + 2 * dataindex]
                  ? this.data[15 + 2 * dataindex][0][4]
                  : constants.MARRYPARTHER,
                w * x + o * (x - 1) + 2.5 * w + o + xx,
                w * y + l * (o + w + w) + yy + w + o
              );
              ctx.fillText(
                this.data[15 + 2 * dataindex]
                  ? this.data[15 + 2 * dataindex][0][5]
                  : "",
                w * x + o * (x - 1) + 2.5 * w + o + xx,
                w * y + l * (o + w + w) + yy + w + o + w / 2
              );
            }
          }
          ctx.stroke();
          if (d == 1) {
            ctx.moveTo(
              w * (x + 0.15) + o * (x - 1) + xx,
              w * (y + 0.15) + l * (o + w + w) + yy
            );
            ctx.lineTo(
              w * (x + 0.85) + o * (x - 1) + xx,
              w * (y + 0.85) + l * (o + w + w) + yy
            );
            ctx.moveTo(
              w * (x + 0.15) + o * (x - 1) + xx,
              w * (y + 0.85) + l * (o + w + w) + yy
            );
            ctx.lineTo(
              w * (x + 0.85) + o * (x - 1) + xx,
              w * (y + 0.15) + l * (o + w + w) + yy
            );
            ctx.stroke();
          }
          break;
        case 3: // abandon
          ctx.beginPath();
          ctx.moveTo(w * x + o * (x - 1) + xx, w * y + yy);
          ctx.lineTo(w * (x + 1) + o * (x - 1) + xx, w * (y + 1) + yy);
          ctx.moveTo(w * x + o * (x - 1) + xx, w * (y + 1) + yy);
          ctx.lineTo(w * (x + 1) + o * (x - 1) + xx, w * y + yy);
          ctx.stroke();
          break;
        case 4: // unborn
          ctx.beginPath();
          ctx.arc(
            w * x + w / 2 + o * (x - 1) + xx,
            w * y + w / 2 + yy,
            w / 8,
            0,
            Math.PI * 2,
            true
          );
          ctx.fill();
          ctx.stroke();
          break;
      }
      // object vertical line
      if (obj) {
        ctx.moveTo(
          w * x + o * (x - 1) + w / 2 + xx,
          w * y - o + ((1 - this.layerLen[y0]) * w) / 2 + yy
        );
        ctx.lineTo(
          w * x + o * (x - 1) + w / 2 + xx,
          w * y + l * (o + w + w) + yy
        );
        ctx.stroke();
      }
      // object name
      if (n)
        ctx.fillText(
          n,
          w * x + o * (x - 1) + xx,
          w * y + l * (o + w + w) + yy + w + o
        );
      if (n1)
        ctx.fillText(
          n1,
          w * x + o * (x - 1) + xx,
          w * y + l * (o + w + w) + yy + w + o + w / 2
        );
      // horizontal line linked with another group
      if (dataindex > 0 && dataindex % 2 == 0 && ll == 1) {
        var len =
          this.keyStatus[dataindex + 1][4] - this.keyStatus[dataindex][4];

        if (len > 0) {
          ctx.moveTo(
            w * x + o * (x - 1) + w + xx,
            w * y + l * (o + w + w) + w / 2 + yy
          );
          ctx.lineTo(
            w * x + o * (x - 1) + len * (w + o) + xx,
            w * y + l * (o + w + w) + w / 2 + yy
          );
          ctx.stroke();
        }
      }
    },

    image() {
      var dataUrl = document
        .getElementById("myCanvas")
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      this.downloadImage(dataUrl, "familytree.png");
    },

    downloadImage(data, filename) {
      var a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    },

    print() {
      const dataUrl = document.getElementById("myCanvas").toDataURL();

      let windowContent = "<!DOCTYPE html>";
      windowContent += "<html>";
      windowContent +=
        "<head><title>" + this.selfName + "的家族圖</title></head>";
      windowContent += "<body>";
      windowContent += '<img src="' + dataUrl + '">';
      windowContent += "</body>";
      windowContent += "</html>";

      const printWin = window.open(
        "123",
        ""
        // "width=" + screen.availWidth + ",height=" + screen.availHeight
      );
      printWin.document.open();
      printWin.document.write(windowContent);

      printWin.document.addEventListener(
        "load",
        function () {
          printWin.focus();
          printWin.print();
          printWin.document.close();
          printWin.close();
        },
        true
      );
    },

    save() {
      var blob = new Blob([JSON.stringify(this.data)], { type: "text/plain" });
      fileSaver.saveAs(blob, "data.txt");
    },

    load() {
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      var selectedFile = e.target.files[0];

      var reader = new FileReader();
      reader.onload = (e) => {
        var content = reader.result;
        var tmpdata = JSON.parse(content);
        var len = tmpdata.length;

        for (var i = 0; i < 23 - len; i++) {
          tmpdata[len + i] = [[0, 0, 0, 0, "", ""]];
        }

        this.data = JSON.parse(JSON.stringify(tmpdata));
        if (this.data) {
          this.draw();
        }
      };
      reader.readAsText(selectedFile);
    },
  },
};
</script>
