import Vue from "vue";
import VueRouter from "vue-router";
import FamilyTree from "../views/FamilyTree.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "familytree",
    component: FamilyTree,
  },
  {
    path: "/about",
    name: "about",
  },
];

const router = new VueRouter({
  routes,
});

export default router;
